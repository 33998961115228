.content {
  position: relative;

  &.searchCar {
    flex: 1;

    .container {
      max-width: inherit;
    }

    @media screen and (min-width: 1280px) {
      margin-right: 5px;
    }

    .option {
      height: 50px;
    }

    .list {
      top: 50px;
    }

    .container:not(.isOpened) {
      .optionSelected:not(:hover) {
        border-color: transparent;
      }
    }
  }

  &.searchCarExtended {
    flex: 1;

    .container {
      max-width: inherit;
    }

    .option {
      height: 48px;
    }

    .list {
      top: 48px;
    }
  }

  &.reversed {
    .list {
      top: inherit;
      bottom: 40px;
      border-top: 1px solid var(--gray-secondary);
      border-bottom: none;
    }

    &.searchCar {
      .list {
        bottom: 50px;
      }
    }

    .container.isOpened {
      .optionSelected {
        border-bottom-color: var(--gray-secondary);
        border-top-color: var(--gray-text);
      }
    }
  }
}

.container {
  position: relative;
  width: 100%;
  // max-width: 300px;

  &:not(.isOpened) {
    .optionSelected {
      &.error {
        border-color: var(--error);
      }
    }
  }

  &.isOpened {
    .optionSelected {
      border-left-color: var(--gray-secondary);
      border-right-color: var(--gray-secondary);
      border-top-color: var(--gray-secondary);

      svg {
        transform: rotate(180deg);
      }
    }
  }

  &.disabled {
    .optionSelected {
      pointer-events: none !important;
      background-color: var(--gray-light);
      color: var(--gray-secondary);
    }
  }
}

.option {
  display: flex;
  align-items: center;
  height: 40px;
  flex-shrink: 0;
  padding: 0 15px 0 20px;
  background-color: var(--white);
  color: var(--black);
  transition: var(--transition);
  cursor: pointer;

  &:hover {
    color: var(--primary);
  }

  &Text {
    flex: 1;
    margin-right: 10px;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  &Selected {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--gray-text);

    svg {
      transition: var(--transition);
      color: var(--header-text-color);
      path {
        transition: var(--transition);
      }
    }

    &:hover {
      border-color: var(--gray-secondary);

      svg {
        path {
          stroke: var(--primary);
        }
      }
    }
  }
}

.list {
  position: absolute;
  left: 0;
  top: 40px;
  width: 100%;
  z-index: 2;
  border: 1px solid var(--gray-secondary);
  border-top: none;
  overflow-y: auto;

  .option:not(:first-child) {
    border-top: 1px solid var(--gray-border);
  }
}

.label {
  position: absolute;
  left: 0;
  font-weight: 500;
  line-height: 1.0625rem;
  color: var(--white);

  @media screen and (min-width: 279px) and (max-width: 1279px) {
    top: -25px;
    font-size: 0.875rem;
  }

  @media screen and (min-width: 1280px) {
    top: -30px;
    font-size: 1.125rem;
  }
}
