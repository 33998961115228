.container {
  display: flex;
  flex-direction: column;
  flex: 1;

  @media screen and (min-width: 279px) and (max-width: 767px) {
    padding: 50px 15px 25px;
  }

  @media screen and (min-width: 768px) and (max-width: 900px) {
    padding: 25px;
  }
  @media screen and (min-width: 900px) {
    padding: 30px 35px 35px;
  }
}

.title {
  margin-bottom: 7px;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.4375rem;
  color: var(--black);
}

.button {
  text-transform: capitalize;

  @media screen and (max-width: 767px) {
    font-size: 0.875rem !important;
    line-height: 1.225em !important;
  }

  @media screen and (min-width: 768px) {
    font-size: 1.125rem !important;
    line-height: 1.4em !important;
  }

  @media screen and (min-width: 768px) {
    max-width: 330px;
    margin: auto auto 0;
  }
}

.description {
  max-width: 280px;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: var(--gray-dark);

  @media screen and (min-width: 279px) and (max-width: 767px) {
    margin-bottom: 10vh;
  }

  @media screen and (min-width: 768px) {
    margin-bottom: 70px;
  }
}

.input {
  margin-bottom: 10px;

  @media screen and (min-width: 768px) {
    max-width: 440px;
  }

  &.invalid {
    margin-bottom: 20px;
  }
}

.suggestion {
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: var(--gray-dark);

  @media screen and (min-width: 279px) and (max-width: 767px) {
    margin-bottom: auto;
  }
}

.reenterButton {
  margin: 0 5px;
  font-weight: 500;
  color: var(--primary);
  background-color: transparent;
  outline: none;
  border: none;
  transition: var(--transition);

  &:hover {
    color: var(--primary-dark);
  }
}
