.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.label {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 0.9375rem;
  color: var(--black);
}

.input {
  width: 100%;
  height: 40px;
  padding: 0 40px 0 20px;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: var(--black);
  border: 1px solid var(--gray-text);
  transition: var(--transition);

  @media screen and (min-width: 279px) and (max-width: 767px) {
    font-size: 16px;
  }

  &:hover,
  &:focus,
  &:active {
    border-color: var(--gray-secondary);
  }

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--gray-secondary);
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--gray-secondary);
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--gray-secondary);
  }

  &.error {
    border-color: var(--error);
  }

  &Container {
    position: relative;
  }

  &:disabled {
    background-color: var(--gray-light);
    color: var(--gray-secondary);
  }
}

.errorIcon {
  position: absolute;
  right: 10px;
  top: 10px;
  color: var(--error);
}

.errorMessage {
  color: var(--error);
}

.errorMessage,
.description {
  position: absolute;
  bottom: -17px;
  left: 0;
  font-size: 0.75rem;
  line-height: 0.625rem;
}

.description {
  color: var(--gray-secondary);
}
